.wl-typing {
  animation: blinker .8s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.wl-menu {
  padding: 1rem 0;
}

.wl-menu a::before,
.wl-menu a::after {
  display: inline-block;
  opacity: 0;
  transition: transform 0.3s, opacity 0.2s;
}

.wl-menu a::before {
  margin-right: 10px;
  content: '[';
  transform: translateX(20px);
}

.wl-menu a::after {
  margin-left: 10px;
  content: ']';
  transform: translateX(-20px);
}

.wl-menu a:hover::before,
.wl-menu a:hover::after,
.wl-menu a:focus::before,
.wl-menu a:focus::after {
  opacity: 1;
  transform: translateX(0px);
}

.wl-social {
  padding: 1rem 0;
}

.wl-social a {
  padding-right: 1rem;
}
